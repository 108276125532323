import React from "react"
import PostCard from "../components/PostCard"
import "./Layout.css"

const PostList = props => {
  const { posts, title } = props

  return (
    <div className="marginFocus" style={styles.container}>
      <h3 style={styles.titleText}>{title}</h3>
      {posts.map(({ node }, index) => (
        <PostCard node={node} index={index} key={index} />
      ))}
    </div>
  )
}

const styles = {
  container: {
    borderTop: "solid 1px #E6E7E8",
    paddingTop: "1.5rem",
    paddingBottom: "2.0rem",
  },
  titleText: {
    marginBottom: "2.0rem",
  },
}

export default PostList
