import React from "react"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import "../components/Grid.css"
import "../components/Layout.css"
import PostList from "../components/PostList"
import Strings from "../constants/Strings"
import Headshot from "../components/Headshot"
import HeadshotImage from "../images/headshot.png"

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

const HEADSHOT_DIM = 140

const IndexPage = props => {
  const { data } = props
  const { top, blogs } = data
  const topNode = top.edges[0].node
  const { frontmatter, htmlAst } = topNode
  const { title } = frontmatter

  return (
    <Layout override="marginStandard">
      <SEO title={Strings.Home.Title} image={HeadshotImage} />
      <div className="marginFocus" style={styles.container}>
        <div>
          <Headshot dimension={HEADSHOT_DIM} screenStyles={styles.headshot} />
          <p style={styles.titleText}>{title}</p>
        </div>
        {renderAst(htmlAst)}
      </div>
      <PostList posts={blogs.edges} title={Strings.Blog.Title} />
    </Layout>
  )
}

const styles = {
  headshot: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "1.6rem",
    marginBottom: "1.6rem",
  },
  container: {
    marginBottom: "2.0rem",
  },
  textContainer: {
    borderBottom: "solid 1px #E6E7E8",
    marginBottom: "1.0rem",
  },
  titleText: {
    textAlign: "left",
    fontSize: "2.4rem",
    fontWeight: 700,
    lineHeight: "2.4rem",
    marginBottom: "2.0rem",
  },
  text: {
    marginBottom: "0.0rem",
  },
  allPostsContainer: {
    borderTop: "solid 1px #E6E7E8",
    paddingTop: "1.5rem",
    paddingBottom: "2.0rem",
  },
  allPostsText: {
    marginBottom: "2.0rem",
  },
}

export default IndexPage

export const listQuery = graphql`
  query ListQuery {
    blogs: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { type: { eq: "blog" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM.DD.YYYY")
            title
          }
          rawMarkdownBody
        }
      }
    }
    top: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { type: { eq: "index" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
          htmlAst
        }
      }
    }
  }
`
