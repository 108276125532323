import React from "react"
import HeadshotImage from "../images/headshot.png"
import { numToPixels } from "../lib/StyleHelper"

const Headshot = ({ dimension, screenStyles }) => {
  const computedStyle = {
    borderRadius: numToPixels(dimension),
    ...screenStyles,
  }
  return (
    <img
      style={computedStyle}
      src={HeadshotImage}
      alt="kurtmcintire"
      height={dimension}
      width={dimension}
    />
  )
}

export default Headshot
