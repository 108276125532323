import React from "react"
import { Link } from "gatsby"
import Strings from "../constants/Strings"
import { createSnippet } from "../lib/SnippetHelper"
import "./Link.css"

const PostCard = props => {
  const { node, index } = props
  const { frontmatter, fields, rawMarkdownBody } = node
  const { title, date } = frontmatter

  return (
    <div style={styles.image}>
      <Link className="link" to={fields.slug} key={index}>
        <div>
          <h6 style={styles.dateText}>{date.toUpperCase()}</h6>
          <h2 className="link" style={styles.titleText}>
            {title}
          </h2>
        </div>
        <div className="link" style={styles.snippetText}>
          {createSnippet(rawMarkdownBody)}
          {Strings.Snippet.Ellipses}
          <b>{Strings.Snippet.Title}</b>
        </div>
      </Link>
    </div>
  )
}

const styles = {
  image: {
    minWidth: "30%",
  },
  dateText: {
    marginTop: "10px",
    marginBottom: "0px",
  },
  snippetText: {
    marginBottom: "10px",
  },
  titleText: {
    marginBlockEnd: "0.6rem",
  },
}

export default PostCard
