export const createSnippet = string =>
  string
    .substring(0, 300)
    .replace(new RegExp("### ", "g"), "")
    .replace(new RegExp("## ", "g"), "")
    .replace(new RegExp("# ", "g"), "")
    .replace(/ *\([^)]*\) */g, " ")
    .replace("\\", "")
    .replace("[", "")
    .replace("]", "")
    .replace("!", "")
    .replace("Cape Cod home", "")
    .replace("[previous post]", "previous post")
    .replace("[Gear List]", "Gear List")
    .replace('<p style="text-align: center;"><i>', "")
    .replace("</i></p>", "")
